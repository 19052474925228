import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";
import { ReactComponent as EditIcon } from "./test.svg";
import typography from "src/theme/typography";
import useDestinations from "src/hooks/useDestinations";

const MyFormModal = () => {
  const { dataDestinations, addDataDestination } = useDestinations();

  useEffect(() => {
    console.log(dataDestinations);
  }, [dataDestinations]);

  const [formData, setFormData] = useState({
    name: "",
    connection_type: "",
    data_template: "",
    need_authentication: false,
    username: "",
    password: "",
    need_encryption: false,
    encryption_type: "",
  });
  const [errors, setErrors] = useState({
    data_template: "",
  });

  const [openFormModal, setOpenFormModal] = useState(false);
  const [openJsonModal, setOpenJsonModal] = useState(false);
  const [jsonContent, setJsonContent] = useState("");

  const handleClickOpenFormModal = () => {
    setFormData({
    name: "",
    connection_type: "",
    data_template: "",
    need_authentication: false,
    username: "",
    password: "",
    need_encryption: false,
    encryption_type: "",
  });

    setOpenFormModal(true);
  };

  const handleCloseFormModal = () => {
    setOpenFormModal(false);
  };

  const handleClickOpenJsonModal = (json) => {
    setJsonContent(json);
    setOpenJsonModal(true);
  };

  const handleCloseJsonModal = () => {
    setOpenJsonModal(false);
  };

  const handleEditClick = (row) => {
    setFormData({
      name: row.name,
      connection_type: row.connection_type,
      data_template: row.data_template,
      need_authenticate: row.need_authenticate,
      username: row.username,
      password: "", // Password is not pre-filled for security reasons
      need_encryption: row.need_encryption,
      encryption_type: row.encryption_type,
    });
    
    setOpenFormModal(true);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
    if (name === "data_template") {
      validateJSON(value);
    }
  };

  const validateJSON = (json) => {
    console.log('JSON à valider:', json);
    try {
      JSON.parse(json);
      setErrors((prevErrors) => ({ ...prevErrors, data_template: "" }));
    } catch (e) {
      console.error('Erreur lors de l\'analyse du JSON:', e.message);
      setErrors((prevErrors) => ({
        ...prevErrors,
        data_template: "Format JSON invalide",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    console.log(errors);
    
    
    // Additional validation before submission
    if (!errors.data_template && formData.name && formData.connection_type) {
      console.log(e);
      
      await addDataDestination(formData);
      console.log(formData);
      handleCloseFormModal();
    } else {
      alert("Please correct the errors in the form");
    }
  };

  const columns = [
    { field: "name", headerName: "Nom", width: 150 },
    { field: "connection_type", headerName: "Type de Connexion", width: 150 },
    {
      field: "data_template",
      headerName: "Data Template (JSON)",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleClickOpenJsonModal(params.value)}
        >
          JSON
        </Button>
      ),
    },
    
    {
      field: "need_authentication",
      headerName: "Besoin d'Authentification",
      width: 150,
      renderCell: (params) =>
        params.value ? (
          <CheckIcon color="success" />
        ) : (
          <CloseIcon color="error" />
        ),
    },
    { field: "username", headerName: "Nom d'Utilisateur", width: 150 },
    {
      field: "need_encryption",
      headerName: "Besoin de Cryptage",
      width: 150,
      renderCell: (params) =>
        params.value ? (
          <CheckIcon color="success" />
        ) : (
          <CloseIcon color="error" />
        ),
    },
    { field: "encryption_type", headerName: "Type de Cryptage", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => handleEditClick(params.row)}
        >
          <EditIcon />
        </Button>
      ),
    },
  ];

  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpenFormModal}
          sx={{ backgroundColor: "blue" }}
        >
          Nouvelle destination
        </Button>
      </Box>
      <Box
        sx={{
          height: 400,
          width: "100%",
          boxShadow: 3,
          borderRadius: 2,
          p: 2,
          backgroundColor: "white",
        }}
      >
        <DataGrid rows={dataDestinations} columns={columns} pageSize={5} />
      </Box>
      <Dialog
        open={openFormModal}
        onClose={handleCloseFormModal}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle> Nouvelle destination</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              mt: 2,
            }}
          >
            <TextField
              label="Nom"
              name="name"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              required
            />
            <FormControl fullWidth required>
              <InputLabel>Type de Connexion</InputLabel>
              <Select
                name="connection_type"
                value={formData.connection_type}
                onChange={handleChange}
              >
                <MenuItem value="API">API</MenuItem>
                <MenuItem value="SFTP">SFTP</MenuItem>
                <MenuItem value="FTP">FTP</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Data Template (JSON)"
              name="data_template"
              value={formData.data_template}
              onChange={handleChange}
              multiline
              rows={4}
              fullWidth
              required
              error={Boolean(errors.data_template)}
              helperText={errors.data_template}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.need_authenticate}
                  onChange={handleChange}
                  name="need_authenticate"
                />
              }
              label="Besoin d'Authentification"
            />
            {formData.need_authenticate && (
              <>
                <TextField
                  label="Nom d'Utilisateur"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  fullWidth
                  required
                />
                <TextField
                  label="Mot de Passe"
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.need_encryption}
                  onChange={handleChange}
                  name="need_encryption"
                />
              }
              label="Besoin de Cryptage"
            />
            {formData.need_encryption && (
              <TextField
                label="Type de Cryptage"
                name="encryption_type"
                value={formData.encryption_type}
                onChange={handleChange}
                fullWidth
                required
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFormModal} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Soumettre
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openJsonModal}
        onClose={handleCloseJsonModal}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>JSON</DialogTitle>
        <DialogContent>
          <Box
            flex={1}
            ml={2}
            p={2}
            border={1}
            borderColor="grey.300"
            borderRadius={4}
          >
            {jsonContent ? (
              <pre>{JSON.stringify(JSON.parse(jsonContent), null, 2)}</pre>
            ) : (
              <p>Sélectionnez un élément pour voir le JSON</p>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseJsonModal} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MyFormModal;

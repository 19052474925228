import { useState, useEffect } from 'react';
import { get, post } from 'src/api';

const baseURL = process.env.REACT_APP_BASE_URL;


export default function useDestinations() {
  const [dataDestinations, setDataDestinations] = useState([]);



  useEffect(() => {
    const fetchDataDestinations = async () => {
      try {
        const response = await get(`${baseURL}/admin/data_destinations`);
        if (response.status === 200) {
          setDataDestinations(response.data.datadestinations);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
      }
    };
    fetchDataDestinations();
  }, []);
  
  const addDataDestination = async (data) => {
    try {
      const response = await post(`${baseURL}/admin/data_destinations`, data);
      console.log(response);
      
      if (response.status === 201) {
        data.id = 999
        setDataDestinations([...dataDestinations, data]);
        console.log(data);
        
      }
    } catch (error) {
      console.error('Erreur lors de la création de la destination :', error);
    }
  }


  return { dataDestinations, addDataDestination };
}

import React, { useState } from "react";
import "maplibre-gl/dist/maplibre-gl.css";

import useMapInitialization from "src/hooks/useMaps/useMapInitialization";

import SyndicModal from "./SyndicModal";
import DetailsModal from "src/pages/DetailsModal";
import { Button } from "@mui/material";

const MapComponent = () => {
  const [selectedSyndic, setSelectedSyndic] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedIncident, setSelectedIncident] = useState(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth * 0.795,
    height: window.innerHeight * 0.85,
  });

  const { mapContainerRef, } = useMapInitialization(
    setSelectedSyndic,
    setIsModalOpen,
    setSelectedIncident,
    setIsDetailsModalOpen,
  );

  return (
    <div
      style={{
        position: "relative",
        height: windowSize.height,
        width: windowSize.width,
      }}
    >
      <div ref={mapContainerRef} style={{ height: "100%" }} />
      {isModalOpen && (
        <SyndicModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          syndic={selectedSyndic}
        />
      )}
      {isDetailsModalOpen && (
        <DetailsModal
          incidentId={selectedIncident}
          open={isDetailsModalOpen}
          onClose={() => setIsDetailsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default MapComponent;

import React, { useState, useRef, useEffect } from 'react';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useMapsLogic } from 'src/hooks/useMaps/useMapsLogics';
import useSpecificZonesData from 'src/hooks/useMaps/useSpecificZonesData';
import { useNavigate } from 'react-router-dom';
import * as turf from '@turf/turf'; // Importer turf

const SpecificZoneCreator = () => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [drawingCoords, setDrawingCoords] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [isNameModalOpen, setIsNameModalOpen] = useState(false);
  const [zoneName, setZoneName] = useState('');
  const polygonLayerId = 'drawingPolygonLayer';
  const { specificZonesData, addSpecificZones, removeSpecificZone } = useSpecificZonesData();
  const navigate = useNavigate();

  // Utiliser le hook pour obtenir bounds et center
  const { bounds, center } = useMapsLogic();

  const [mapLoaded, setMapLoaded] = useState(false);

  // États pour la pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // États pour la modal de suppression
  const [zoneToDelete, setZoneToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  // Pour la réactivité
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  // Initialiser la carte avec centrage automatique
  useEffect(() => {
    if (!bounds || !center) return;

    const map = new maplibregl.Map({
      container: mapContainerRef.current,
      style:
        'https://api.maptiler.com/maps/basic-v2/style.json?key=XLIbvWzZzywSuTAXpTMU',
      center: center,
      zoom: 12,
      attributionControl: false,
      pitchWithRotate: false,
      dragRotate: false,
    });
    mapRef.current = map;

    map.addControl(new maplibregl.FullscreenControl());
    map.addControl(new maplibregl.NavigationControl(), 'bottom-left');

    map.on('load', () => {
      map.fitBounds(
        [
          [bounds.west, bounds.south],
          [bounds.east, bounds.north],
        ],
        {
          padding: 20,
          duration: 200,
        }
      );

      setMapLoaded(true);
    });

    map.on('moveend', () => {
      const currentCenter = map.getCenter();
      const currentZoom = map.getZoom();
      const clampedCenter = [
        Math.max(bounds.west, Math.min(currentCenter.lng, bounds.east)),
        Math.max(bounds.south, Math.min(currentCenter.lat, bounds.north)),
      ];
      const minZoom = 10;

      if (
        currentCenter.lng !== clampedCenter[0] ||
        currentCenter.lat !== clampedCenter[1]
      ) {
        map.easeTo({ center: clampedCenter, duration: 200 });
      }
      if (currentZoom < minZoom) {
        map.setZoom(minZoom);
      }
    });

    map.on('zoomend', () => {
      const currentZoom = map.getZoom();
      const minZoom = 10;
      if (currentZoom < minZoom) {
        map.setZoom(minZoom);
      }
    });

    return () => {
      map.remove();
    };
  }, [bounds, center]);

  // Gestion du dessin et affichage des zones spécifiques
  useEffect(() => {
    if (!mapLoaded || !mapRef.current) return;

    const map = mapRef.current;

    // Afficher les zones spécifiques existantes sur la carte
    const displaySpecificZones = () => {
      const zonesGeoJSON = {
        type: 'FeatureCollection',
        features: specificZonesData.map((zone) => {
          const coordinates = JSON.parse(zone.shape).map((coord) => [
            coord[1],
            coord[0],
          ]);
          return {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [coordinates],
            },
            properties: {
              id: zone.id,
              name: zone.name,
            },
          };
        }),
      };

      if (map.getSource('specificZones')) {
        map.getSource('specificZones').setData(zonesGeoJSON);
      } else {
        map.addSource('specificZones', {
          type: 'geojson',
          data: zonesGeoJSON,
        });

        map.addLayer({
          id: 'specificZonesLayer',
          type: 'fill',
          source: 'specificZones',
          layout: {},
          paint: {
            'fill-color': '#0000FF', // Bleu
            'fill-opacity': 0.5,
          },
        });

        map.addLayer({
          id: 'specificZonesOutline',
          type: 'line',
          source: 'specificZones',
          layout: {},
          paint: {
            'line-color': '#0000FF',
            'line-width': 2,
          },
        });

        // Ajouter une interaction pour afficher le nom de la zone au survol
        map.on('mouseenter', 'specificZonesLayer', (e) => {
          map.getCanvas().style.cursor = 'pointer';
          const coordinates = e.lngLat;
          const { name } = e.features[0].properties;

          // Créer et afficher la popup
          const popup = new maplibregl.Popup({
            closeButton: false,
            closeOnClick: false,
          })
            .setLngLat(coordinates)
            .setHTML(`<strong>${name}</strong>`)
            .addTo(map);

          map.on('mouseleave', 'specificZonesLayer', () => {
            map.getCanvas().style.cursor = '';
            popup.remove();
          });
        });
      }
    };

    displaySpecificZones();

    if (isDrawing) {
      const handleMapClick = (e) => {
        const coords = [e.lngLat.lng, e.lngLat.lat];
        setDrawingCoords((prev) => [...prev, coords]);
      };

      map.on('click', handleMapClick);

      map.getCanvas().style.cursor = 'crosshair';

      return () => {
        map.off('click', handleMapClick);
        map.getCanvas().style.cursor = '';
      };
    } else {
      map.getCanvas().style.cursor = '';

      // Nettoyer le polygone en cours de dessin
      if (map.getLayer(polygonLayerId)) {
        map.removeLayer(polygonLayerId);
      }
      if (map.getSource('drawingPolygon')) {
        map.removeSource('drawingPolygon');
      }
      setDrawingCoords([]);
    }
  }, [isDrawing, mapLoaded, drawingCoords, specificZonesData]);

  // Gestion du dessin du polygone
  useEffect(() => {
    if (!mapLoaded || !mapRef.current || !isDrawing) return;

    const map = mapRef.current;

    // Mettre à jour le polygone en cours de dessin
    if (drawingCoords.length > 0) {
      const drawingGeoJSON = {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [drawingCoords],
        },
      };

      if (map.getSource('drawingPolygon')) {
        map.getSource('drawingPolygon').setData(drawingGeoJSON);
      } else {
        map.addSource('drawingPolygon', {
          type: 'geojson',
          data: drawingGeoJSON,
        });
        map.addLayer({
          id: polygonLayerId,
          type: 'fill',
          source: 'drawingPolygon',
          layout: {},
          paint: {
            'fill-color': '#00FF00',
            'fill-opacity': 0.5,
          },
        });
      }
    }
  }, [drawingCoords, isDrawing, mapLoaded]);

  const handleValidate = () => {
    if (drawingCoords.length < 3) {
      alert('Veuillez dessiner au moins trois points pour former un polygone.');
      return;
    }
    setIsNameModalOpen(true);
  };

  const handleNameSubmit = () => {
    const shapeCoords = drawingCoords.map((coord) => [coord[1], coord[0]]);
    const shape = JSON.stringify(shapeCoords);
    const zoneData = {
      id: Date.now(), // Utilisez un identifiant unique approprié
      name: zoneName,
      shape: shape,
    };
    addSpecificZones(zoneData);
    console.log({ specific_zone: zoneData });
    setZoneName('');
    setIsNameModalOpen(false);
    setIsDrawing(false);
    setDrawingCoords([]);
  };

  // Gestion de la pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Gestion de la suppression
  const handleDeleteClick = (zone) => {
    setZoneToDelete(zone);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = () => {
    if (zoneToDelete) {
      removeSpecificZone(zoneToDelete.id);
      setZoneToDelete(null);
      setIsDeleteModalOpen(false);
      // Réinitialiser la pagination si nécessaire
      if ((page + 1) * rowsPerPage > specificZonesData.length - 1) {
        setPage(Math.max(0, page - 1));
      }
    }
  };

  // Gestion du recentrage sur la zone
  const handleViewZone = (zone) => {
    if (!mapRef.current) return;

    const map = mapRef.current;

    // Récupérer les coordonnées de la zone
    const coordinates = JSON.parse(zone.shape).map((coord) => [coord[1], coord[0]]);

    // Créer un objet GeoJSON pour la zone
    const zoneGeoJSON = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [coordinates],
      },
    };

    // Calculer les limites (bounds) de la zone
    const bbox = turf.bbox(zoneGeoJSON);

    // Recentrer et zoomer la carte sur la zone
    map.fitBounds(bbox, {
      padding: 40,
      duration: 1000,
    });
  };

  return (
    <>
      {/* En-tête avec AppBar */}
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Gestion des zones spécifiques
          </Typography>
          {isDrawing ? (
            <>
              <IconButton color="inherit" onClick={handleValidate}>
                <CheckIcon />
              </IconButton>
              <IconButton color="inherit" onClick={() => setIsDrawing(false)}>
                <CloseIcon />
              </IconButton>
            </>
          ) : (
            <IconButton color="inherit" onClick={() => setIsDrawing(true)}>
              <EditIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      {/* Contenu principal avec Grid */}
      <Grid container>
        {/* Carte */}
        <Grid item xs={12} md={8}>
          <div
            ref={mapContainerRef}
            style={{
              height: isSmallScreen ? '50vh' : '80vh',
              width: '100%',
              cursor: isDrawing ? 'crosshair' : 'default',
            }}
          />
        </Grid>

        {/* Tableau des zones */}
        <Grid item xs={12} md={4}>
          <TableContainer component={Paper} style={{ maxHeight: '80vh' }}>
            <Table stickyHeader aria-label="Liste des zones spécifiques">
              <TableHead>
                <TableRow>
                  <TableCell>Nom de la zone</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {specificZonesData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((zone) => (
                    <TableRow key={zone.id} hover>
                      <TableCell component="th" scope="row">
                        {zone.name}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          edge="end"
                          color="primary"
                          onClick={() => handleViewZone(zone)}
                        >
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton
                          edge="end"
                          color="error"
                          onClick={() => handleDeleteClick(zone)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={specificZonesData.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </Grid>

      {/* Modale pour le nom de la zone */}
      <Dialog open={isNameModalOpen} onClose={() => setIsNameModalOpen(false)}>
        <DialogTitle>Entrez le nom de la zone</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nom de la zone"
            type="text"
            fullWidth
            value={zoneName}
            onChange={(e) => setZoneName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsNameModalOpen(false)} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleNameSubmit} color="primary">
            Valider
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modale de confirmation de suppression */}
      <Dialog
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <DialogTitle>Confirmer la suppression</DialogTitle>
        <DialogContent>
          Êtes-vous sûr de vouloir supprimer la zone "{zoneToDelete?.name}" ?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsDeleteModalOpen(false)}
            color="secondary"
          >
            Annuler
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SpecificZoneCreator;
import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { useUser } from "./contexts/authContext";

import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
import UserPage from "./pages/UserPage";
import TerritoriesPage from "./pages/TerritoriesPage";
import LoginPage from "./pages/LoginPage";
import Page404 from "./pages/Page404";
import DashboardAppPage from "./pages/DashboardAppPage";
import MapsPage from "./pages/MapsPage";
import Details from "./pages/Details";
import CityPage from "./pages/cityPage";
import RolePage from "./pages/RolePage";
import LoadingSpinner from "./components/loadingSpinner";
import DataDestinations from "./pages/DataDestinations";
import SpecificZoneCreator from "./pages/SpecificZoneCreator";

function ProtectedRoute({ children }) {
  const { token, loading } = useUser();

  if (loading) {
    return <LoadingSpinner />;
  }

  return token ? children : <Navigate to="/login" />;
}

export default function Router() {
  const { loading, token } = useUser();

  const routes = useRoutes([
    {
      path: "/dashboard",
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: "app", element: <MapsPage /> },
        { path: "user", element: <UserPage /> },
        { path: "roles", element: <RolePage /> },
        { path: ":incidentId", element: <Details /> },
        { path: "territories", element: <TerritoriesPage /> },
        { path: "cities", element: <CityPage /> },
        { path: "destination", element: <DataDestinations /> },
        { path: "zones", element: <SpecificZoneCreator /> },
      ],
    },
    {
      path: "/incident",
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/incident/maps" />, index: true },
        { path: ":incidentId", element: <Details /> },
      ],
    },
    {
      path: "login",
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (token && window.location.pathname === "/login") {
    return <Navigate to="/dashboard" />;
  }

  return routes;
}

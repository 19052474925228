import { useState, useEffect } from "react";
import { get, post, remove } from "src/api";

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useSpecificZonesData() {
  const [specificZonesData, setSpecificZonesData] = useState([]);
  

  useEffect(() => {
    const fetchDataDestinations = async () => {
      try {
        const response = await get(`${baseURL}/admin/specific_zones`);
        if (response.status === 200) {
            setSpecificZonesData(response.data.specific_zones);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };
    fetchDataDestinations();
  }, []);

  const addSpecificZones = async (data) => {
    try {
      const response = await post(`${baseURL}/admin/specific_zones`, data);
      console.log(response);
      
      if (response.status === 201) {
        data.id = 999
        setSpecificZonesData([...specificZonesData, data]);
        console.log(data);
        
      }
    } catch (error) {
      console.error('Erreur lors de la création de la destination :', error);
    }
  }

  const removeSpecificZone = async (id) => {
    try {
      const response = await remove(`${baseURL}/admin/specific_zones/${id}`);
      if (response.status === 200) {
        setSpecificZonesData(specificZonesData.filter((specificZone) => specificZone.id !== id));
      }
    } catch (error) {
      console.error('Erreur lors de la suppression de la destination :', error);
    }
  }


  return { specificZonesData, addSpecificZones, removeSpecificZone };
}

import { loginWithCredentials } from "./authService";

class WebSocketService {
  constructor() {
    this.socket = null;
    this.channelCallbacks = {}; // Callbacks par channel
    this.subscribedChannels = new Set(); // Canaux déjà abonnés
    this.channelStates = {}; // États des channels
    this.reconnectInterval = 5000; // Intervalle de reconnexion en ms
    this.reconnectTimeout = null; // Timeout pour la reconnexion
    this.token = null;
    this.initialChannel = null;
    this.initialCallback = null;
    this.pingInterval = 10000; // Intervalle entre chaque ping en ms
    this.pingTimeout = 5000; // Temps d'attente de réponse au ping en ms
    this.pingIntervalId = null;
    this.pongTimeoutId = null;
    this.stateChangeCallback = null;
    this.channelStateChangeCallback = null;
    this.unauthorizedCallback = null; // Callback pour gérer les erreurs de connexion
    this.isReconnecting = false; // Empêcher les reconnections multiples
  }

  async connect(initialChannel = null, token, initialCallback = null) {
    this.token = token;
    this.initialChannel = initialChannel;
    this.initialCallback = initialCallback;

    console.log("Attempting to connect WebSocket...");

    if (this.isConnected()) {
      console.log("WebSocket is already connected");
      if (initialChannel && initialCallback) {
        console.log("Subscribing to initial channel after existing connection");
        this.subscribeToChannel(
          initialChannel.name,
          initialChannel.params,
          initialCallback
        );
      }
      return;
    }

    const wsUrl = `wss://backend.v1.digihelp.fr/cable?token=${encodeURIComponent(
      token
    )}`;
    this.socket = new WebSocket(wsUrl);

    this.socket.onopen = () => {
      console.log("WebSocket connection established");
      this.startPing();
      this.triggerStateChange();

      if (initialChannel && initialCallback) {
        console.log("Subscribing to initial channel on connection open");
        this.subscribeToChannel(
          initialChannel.name,
          initialChannel.params,
          initialCallback
        );
      }

      // Réabonner tous les channels après reconnexion
      this.resubscribeToAllChannels();
    };

    this.socket.onmessage = async (e) => {
      const message = JSON.parse(e.data);
      this.resetPongTimeout();

      if (message.type === "disconnect" && message.reason === "unauthorized") {
        console.warn("Unauthorized access, attempting reauthentication...");
        await this.handleUnauthorized();
        return;
      }

      const identifier = message.identifier
        ? JSON.parse(message.identifier)
        : {};
      if (message.type === "confirm_subscription") {
        console.log(
          `Subscription confirmed for channel: ${identifier.channel}`
        );
        this.updateChannelState(identifier.channel, "connected");
      } else if (message.type === "reject_subscription") {
        console.log(`Subscription rejected for channel: ${identifier.channel}`);
        this.updateChannelState(identifier.channel, "rejected");
      }

      const callback = this.channelCallbacks[identifier.channel];
      if (callback) {
        callback(message);
      }
    };

    this.socket.onerror = (error) => {
      console.error("WebSocket error:", error.message);
      this.updateAllChannelsState("disconnected");
      this.startReconnect();
    };

    this.socket.onclose = (e) => {
      console.log("WebSocket connection closed:", e);
      this.updateAllChannelsState("disconnected");
      this.subscribedChannels.clear();
      this.stopPing();
      this.triggerStateChange();
      this.startReconnect();
    };
  }

  async handleUnauthorized() {
    try {
      // Tenter de récupérer un nouveau token
      const newToken = await this.reauthenticate();
      if (newToken) {
        console.log("Reauthentication successful, reconnecting WebSocket...");
        this.token = newToken;
        await this.connect(
          this.initialChannel,
          this.token,
          this.initialCallback
        );
      } else {
        console.warn("Reauthentication failed, disconnecting...");
        this.disconnect();
      }
    } catch (error) {
      console.error("Reauthentication error:", error);
      this.disconnect();
    }
  }

  async reauthenticate() {
    try {
      // Remplacez cette fonction par votre logique pour obtenir un nouveau token
      const newToken = await loginWithCredentials(); // Exemple: une fonction qui fait une requête pour obtenir un nouveau token
      return newToken;
    } catch (error) {
      console.error("Failed to reauthenticate:", error);
      return null;
    }
  }

  startPing() {
    if (this.pingIntervalId) return;

    this.pingIntervalId = setInterval(() => {
      if (this.isConnected()) {
        console.log("Sending ping to server");
        this.socket.send(JSON.stringify({ type: "ping" }));

        this.pongTimeoutId = setTimeout(() => {
          console.log("Pong timeout - reconnecting");
          this.socket.close();
        }, this.pingTimeout);
      }
    }, this.pingInterval);
  }

  stopPing() {
    if (this.pingIntervalId) {
      clearInterval(this.pingIntervalId);
      this.pingIntervalId = null;
    }
    if (this.pongTimeoutId) {
      clearTimeout(this.pongTimeoutId);
      this.pongTimeoutId = null;
    }
  }

  resetPongTimeout() {
    if (this.pongTimeoutId) {
      clearTimeout(this.pongTimeoutId);
      this.pongTimeoutId = null;
    }
  }

  isConnected() {
    return this.socket && this.socket.readyState === WebSocket.OPEN;
  }

  getReadyState() {
    return this.socket ? this.socket.readyState : WebSocket.CLOSED;
  }

  onStateChange(callback) {
    this.stateChangeCallback = callback;
  }

  triggerStateChange() {
    if (this.stateChangeCallback) {
      this.stateChangeCallback(this.getReadyState());
    }
  }

  subscribeToChannel(channelName, params, callback) {
    console.log(`Subscribing to channel: ${channelName}`);
    const channelIdentifier = JSON.stringify({
      channel: channelName,
      ...params,
    });

    if (this.subscribedChannels.has(channelIdentifier)) {
      console.log(`Already subscribed to channel: ${channelName}`);
      return;
    }

    this.channelCallbacks[channelName] = callback;
    this.subscribedChannels.add(channelIdentifier);
    this.updateChannelState(channelName, "connecting");

    if (this.isConnected()) {
      console.log(`Sending subscribe command for channel: ${channelName}`);
      const message = JSON.stringify({
        command: "subscribe",
        identifier: channelIdentifier,
      });
      this.socket.send(message);
    } else {
      console.error(
        `Cannot subscribe to channel ${channelName}: WebSocket not connected`
      );
    }
  }

  resubscribeToAllChannels() {
    console.log("Resubscribing to all channels...");
    this.subscribedChannels.forEach((channelIdentifier) => {
      const { channel, ...params } = JSON.parse(channelIdentifier);
      console.log(`Attempting to resubscribe to channel: ${channel}`, params);
      const callback = this.channelCallbacks[channel];
      if (callback) {
        console.log(`Resubscribing to channel: ${channel}`);
        this.subscribeToChannel(channel, params, callback);
      } else {
        console.error(`No callback found for channel: ${channel}`);
      }
    });
  }

  unsubscribeFromChannel(channelName, params) {
    const channelIdentifier = JSON.stringify({
      channel: channelName,
      ...params,
    });
    const message = JSON.stringify({
      command: "unsubscribe",
      identifier: channelIdentifier,
    });

    if (this.isConnected()) {
      console.log(`Sending unsubscribe command for channel: ${channelName}`);
      this.socket.send(message);
    }

    delete this.channelCallbacks[channelName];
    this.subscribedChannels.delete(channelIdentifier);
    this.updateChannelState(channelName, "disconnected");
  }

  updateChannelState(channelName, state) {
    this.channelStates[channelName] = state;
    this.triggerChannelStateChange(channelName);
  }

  updateAllChannelsState(state) {
    this.subscribedChannels.forEach((channelIdentifier) => {
      const channel = JSON.parse(channelIdentifier).channel;
      this.updateChannelState(channel, state);
    });
  }

  getChannelState(channelName) {
    return this.channelStates[channelName] || "disconnected";
  }

  onChannelStateChange(callback) {
    this.channelStateChangeCallback = callback;
  }

  triggerChannelStateChange(channelName) {
    if (this.channelStateChangeCallback) {
      this.channelStateChangeCallback(
        channelName,
        this.getChannelState(channelName)
      );
    }
  }

  sendMessage(message) {
    try {
      if (this.isConnected()) {
        console.log("Sending message:", message);
        this.socket.send(JSON.stringify(message));
      }
    } catch (error) {
      console.log(error);
    }
  }


  startReconnect() {
    if (this.reconnectTimeout || this.isReconnecting || this.isConnected())
      return;

    console.log(
      `Attempting to reconnect in ${this.reconnectInterval / 1000} seconds...`
    );
    this.isReconnecting = true;

    this.reconnectTimeout = setTimeout(async () => {
      this.reconnectTimeout = null;
      if (!this.isConnected()) {
        await this.connect(
          this.initialChannel,
          this.token,
          this.initialCallback
        );
      }
      this.isReconnecting = false;
    }, this.reconnectInterval);
  }

  disconnect() {
    if (this.socket) {
      console.log("Disconnecting WebSocket");
      this.socket.onclose = null; // Prevent triggering `onclose` handler during intentional disconnection
      this.socket.close();
      this.socket = null;
      this.channelCallbacks = {};
      this.subscribedChannels.clear();
      this.stopPing();
      clearTimeout(this.reconnectTimeout);
      this.reconnectTimeout = null;
      this.isReconnecting = false;
    }
  }

  onclose = (e) => {
    console.log("WebSocket connection closed:", e);
    if (e.code !== 1000) {
      // 1000 is a normal closure code
      this.startReconnect();
    }
    this.updateAllChannelsState("disconnected");
    this.subscribedChannels.clear();
    this.stopPing();
    this.triggerStateChange();
  };

  handleError(callback) {
    this.socket.onerror = (error) => {
      console.error("WebSocket encountered error: ", error.message);
      if (callback) {
        callback(error);
      }
    };
  }
}

export default new WebSocketService();

import { useEffect, useRef, useState } from "react";
import maplibregl from "maplibre-gl";
import { post } from "src/api";
import { useMap } from "src/hooks/useMaps/useMap";
import { useMapsLogic } from "./useMapsLogics";
import listCameras from "./listCameras.json";
import useSpecificZonesData from "./useSpecificZonesData";

const useMapInitialization = (
  setSelectedSyndic,
  setIsModalOpen,
  setSelectedIncident,
  setIsDetailsModalOpen,
) => {
  const { cameras } = listCameras;
  const mapContainerRef = useRef(null);
  const mapRef = useRef();
  const markerRefs = useRef({});
  const popupRef = useRef(null);
  const { createPolygonWithHole } = useMap();
  const { markersData, bounds, center, userPosition } = useMapsLogic();
  const [mapLoaded, setMapLoaded] = useState(false);
  let hoverPopup = null;

  const { specificZonesData } = useSpecificZonesData();

  const convertZonesToGeoJSON = (zones) => {
    return {
      type: "FeatureCollection",
      features: zones.map((zone) => {
        // Parser la chaîne de caractères en tableau de coordonnées
        const coordinates = JSON.parse(zone.shape);

        // Inverser les coordonnées pour obtenir [longitude, latitude]
        const reversedCoordinates = coordinates.map((coord) => [
          coord[1],
          coord[0],
        ]);

        // Fermer le polygone en ajoutant le premier point à la fin s'il n'est pas déjà fermé
        if (
          reversedCoordinates[0][0] !==
            reversedCoordinates[reversedCoordinates.length - 1][0] ||
          reversedCoordinates[0][1] !==
            reversedCoordinates[reversedCoordinates.length - 1][1]
        ) {
          reversedCoordinates.push(reversedCoordinates[0]);
        }

        return {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [reversedCoordinates],
          },
          properties: {
            id: zone.id,
            name: zone.name,
            territory_name: zone.territory_name,
          },
        };
      }),
    };
  };

  // Function to update incident markers
  const updateIncidentMarkers = () => {
    const existingMarkers = markerRefs.current;
    const newMarkerIds = new Set(markersData.map((m) => m.properties.id));

    // Remove markers that no longer exist
    Object.keys(existingMarkers).forEach((id) => {
      console.log(id);
      if (!newMarkerIds.has(id)) {
        existingMarkers[id].remove();
      }
    });
    // Add new markers
    markersData.forEach((marker) => {
      const markerId = marker.properties.id;

      if (markerRefs.current[markerId]) {
        const existingMarker = markerRefs.current[markerId];

        const newChildrenCount = marker.properties.children
          ? marker.properties.children.length
          : 0;
        const markerElement = existingMarker.getElement();

        const numberContainer =
          markerElement.querySelector(".number-container");
        if (numberContainer) {
          numberContainer.innerText =
            newChildrenCount > 0 ? newChildrenCount + 1 : 1;
        }
      }

      if (!existingMarkers[markerId]) {
        const el = createCustomMarker(marker, ["50px", "69px"], 999);
        const newMarker = new maplibregl.Marker({
          element: el,
          anchor: "bottom",
        })
          .setLngLat(marker.geometry.coordinates)
          .addTo(mapRef.current)
          .getElement()
          .addEventListener("click", () => {
            setSelectedIncident(markerId);
            setIsDetailsModalOpen(true);
          });
        existingMarkers[markerId] = newMarker;
      }
    });
  };

  const updatePositionMarker = () => {
    // Parcourir les clés de l'objet markerRefs.current
    Object.keys(markerRefs.current).forEach((markerId) => {
      // Vérifier si le marqueur est associé à un utilisateur (par exemple en vérifiant une propriété personnalisée)
      if (markerRefs.current[markerId]?.isUserMarker) {
        // Supprimer le marqueur si l'utilisateur associé n'est plus dans userPosition
        if (!userPosition[markerId]) {
          markerRefs.current[markerId].remove();
          delete markerRefs.current[markerId];
        }
      }
    });

    // Ajouter ou mettre à jour les marqueurs pour les utilisateurs dans userPosition
    Object.keys(userPosition).forEach((id) => {
      const marker = userPosition[id];
      const markerId = marker.properties.id;
      const newCoordinates = [
        parseFloat(marker.geometry.coordinates[0]),
        parseFloat(marker.geometry.coordinates[1]),
      ];

      if (!markerRefs.current[markerId]) {
        // Créer un nouveau marqueur si celui-ci n'existe pas
        marker.properties = { typeIncident: "camera" };
        const el = createCustomMarker(marker, ["40px", "40px"], "camera", 1);
        const newMarker = new maplibregl.Marker({
          element: el,
          anchor: "bottom",
        })
          .setLngLat(newCoordinates)
          .addTo(mapRef.current);

        // Marquer ce marqueur comme un marqueur utilisateur
        newMarker.isUserMarker = true;

        // Stocker l'instance Marker complète
        markerRefs.current[markerId] = newMarker;
      } else {
        // Si le marqueur existe déjà, on met à jour sa position
        const existingMarker = markerRefs.current[markerId];
        existingMarker.setLngLat(newCoordinates); // Mise à jour des coordonnées
      }
    });
  };

  // Function to update camera markers
  const updateCameraMarkers = () => {
    cameras.forEach((marker) => {
      const markerId = marker.name;
      if (!markerRefs.current[markerId]) {
        marker.properties = { typeIncident: "camera" };
        const el = createCustomMarker(marker, ["40px", "40px"], "camera", 1);
        const newMarker = new maplibregl.Marker({
          element: el,
          anchor: "bottom",
        })
          .setLngLat([
            parseFloat(marker.longitude),
            parseFloat(marker.latitude),
          ])
          .addTo(mapRef.current)
          .getElement();

        newMarker.addEventListener("click", () => {
          if (popupRef.current) {
            popupRef.current.remove();
          }
          popupRef.current = new maplibregl.Popup({
            closeButton: false,
            closeOnClick: false,
          })
            .setLngLat([
              parseFloat(marker.longitude),
              parseFloat(marker.latitude),
            ])
            .setHTML(`<p>${marker.name}</p>`)
            .addTo(mapRef.current);
        });

        newMarker.addEventListener("mouseleave", () => {
          if (popupRef.current) {
            popupRef.current.remove();
            popupRef.current = null;
          }
        });

        markerRefs.current[markerId] = newMarker;
      }
    });
  };

  const createCustomMarker = (marker, size, type, zIndex) => {
    const el = document.createElement("div");
    let incidentImage;

    if (marker.properties.typeIncident === "camera") {
      incidentImage = `${process.env.REACT_APP_CDN_URL}/points_of_interests/125%20x%20125/poi_video_protection.png`;
    } else if (marker.properties.typeIncident === "icone_syndic") {
      incidentImage = `${process.env.REACT_APP_CDN_URL}/points_of_interests/50%20x%2050/poi_copropriete.png`;
    } else {
      incidentImage = `${process.env.REACT_APP_CDN_URL}/problems_types_markers/125%20x%20172/${marker.properties.marker}`;
    }

    el.className = "marker";
    el.style.width = size[0];
    el.style.height = size[1];
    el.style.cursor = "pointer";
    el.style.zIndex = zIndex;

    const imageContainer = document.createElement("div");
    imageContainer.style.backgroundImage = `url(${incidentImage})`;
    imageContainer.style.width = "100%";
    imageContainer.style.height = "100%";
    imageContainer.style.backgroundSize = "cover";
    imageContainer.style.borderRadius = "50%";

    if (marker.properties?.children && marker.properties.children.length >= 0) {
      const numberContainer = document.createElement("div");
      numberContainer.style.marginBottom = "5px";
      numberContainer.style.backgroundColor = "rgba(0, 0, 0, 0.7)";
      numberContainer.style.color = "white";
      numberContainer.style.padding = "2px 5px";
      numberContainer.style.borderRadius = "5px";
      numberContainer.style.fontSize = "12px";
      numberContainer.style.fontWeight = "bold";
      numberContainer.style.textAlign = "center";
      numberContainer.innerText = marker.properties.children.length + 1 || "1";

      el.appendChild(numberContainer);
    }
    el.appendChild(imageContainer);

    return el;
  };

  useEffect(() => {
    if (mapLoaded) {
      updateIncidentMarkers();
      updateCameraMarkers();
    }
  }, [mapLoaded, markersData, cameras]);

  useEffect(() => {
    if (mapLoaded) {
      updatePositionMarker();
    }
  }, [mapLoaded, userPosition]);

  useEffect(() => {
    if (!bounds) {
      console.log("Bounds are not defined yet");
      return;
    }

    const initializeMap = () => {
      const map = new maplibregl.Map({
        container: mapContainerRef.current,
        style:
          "https://api.maptiler.com/maps/basic-v2/style.json?key=XLIbvWzZzywSuTAXpTMU",
        center: [
          (bounds.west + bounds.east) / 2,
          (bounds.north + bounds.south) / 2,
        ],
        zoom: 2,
        attributionControl: false,
        pitchWithRotate: false,
        dragRotate: false,
      });

      mapRef.current = map;
      map.addControl(new maplibregl.FullscreenControl());
      map.addControl(new maplibregl.NavigationControl(), "bottom-left");

      map.on("load", () => {
        const polygonWithHole = createPolygonWithHole(
          center,
          bounds.radius,
          bounds
        );
        map.addSource("polygonWithHole", {
          type: "geojson",
          data: polygonWithHole,
        });

        map.addLayer({
          id: "polygonWithHole",
          type: "fill",
          source: "specificZones",
          layout: {},
          paint: {
            "fill-color": "#f00",
            "fill-opacity": 0.2,
          },
          filter: ["==", "$type", "Polygon"],
        });

        map.fitBounds(
          [
            [bounds.west, bounds.south],
            [bounds.east, bounds.north],
          ],
          {
            padding: 20,
            duration: 200,
          }
        );

        const specificZonesGeoJSON = convertZonesToGeoJSON(specificZonesData);

        map.addSource("specificZones", {
          type: "geojson",
          data: specificZonesGeoJSON,
        });

        // Ajouter une couche pour afficher les polygones
        map.addLayer({
          id: "specificZonesLayer",
          type: "fill",
          source: "specificZones",
          layout: {},
          paint: {
            "fill-color": "#0000FF", // Bleu
            "fill-opacity": 0.5, // Transparence
          },
        });

        // Optionnel : Ajouter une bordure autour des polygones
        map.addLayer({
          id: "specificZonesOutline",
          type: "line",
          source: "specificZones",
          layout: {},
          paint: {
            "line-color": "#0000FF",
            "line-width": 2,
          },
        });

        setMapLoaded(true);
      });

      map.on("moveend", () => {
        const currentCenter = map.getCenter();
        const currentZoom = map.getZoom();
        const clampedCenter = [
          Math.max(bounds.west, Math.min(currentCenter.lng, bounds.east)),
          Math.max(bounds.south, Math.min(currentCenter.lat, bounds.north)),
        ];
        const minZoom = 10;

        if (
          currentCenter.lng !== clampedCenter[0] ||
          currentCenter.lat !== clampedCenter[1]
        ) {
          map.easeTo({ center: clampedCenter, duration: 200 });
        }
        if (currentZoom < minZoom) {
          map.setZoom(minZoom);
        }
      });

      map.on("mouseenter", "specificZonesLayer", (e) => {
        map.getCanvas().style.cursor = "pointer";

        const coordinates = e.lngLat;
        const { name } = e.features[0].properties;

        // Créer et afficher la popup
        hoverPopup = new maplibregl.Popup({
          closeButton: false,
          closeOnClick: false,
        })
          .setLngLat(coordinates)
          .setHTML(`<strong>${name}</strong>`)
          .addTo(map);
      });

      map.on("mouseleave", "specificZonesLayer", () => {
        map.getCanvas().style.cursor = "";
        if (hoverPopup) {
          hoverPopup.remove();
          hoverPopup = null;
        }
      });

      map.on("zoomend", () => {
        const currentZoom = map.getZoom();
        const minZoom = 10;
        if (currentZoom < minZoom) {
          map.setZoom(minZoom);
        }
      });

      map.on("contextmenu", async (coords) => {
        removeSyndicMarkers();
        console.log(coords);

        const res = await post(process.env.REACT_APP_SYNDICS_URL, {
          latitude: coords.lngLat.lat,
          longitude: coords.lngLat.lng,
          distance: "0.05",
        });

        res.data.forEach((marker) => {
          marker.properties = { typeIncident: "icone_syndic" };
          const el = createCustomMarker(marker, ["40px", "40px"], "syndic", 1);
          const newMarker = new maplibregl.Marker({
            element: el,
            anchor: "bottom",
          })
            .setLngLat([
              parseFloat(marker.longitude),
              parseFloat(marker.latitude),
            ])
            .addTo(mapRef.current)
            .getElement();

          newMarker.addEventListener("click", () => {
            setSelectedSyndic(marker);
            setIsModalOpen(true);
          });

          newMarker.addEventListener("mouseenter", () => {
            if (popupRef.current) {
              popupRef.current.remove();
            }
            popupRef.current = new maplibregl.Popup({
              closeButton: false,
              closeOnClick: false,
            })
              .setLngLat([
                parseFloat(marker.longitude),
                parseFloat(marker.latitude),
              ])
              .setHTML(`<p>${marker.name}</p>`)
              .addTo(mapRef.current);
          });

          newMarker.addEventListener("mouseleave", () => {
            if (popupRef.current) {
              popupRef.current.remove();
              popupRef.current = null;
            }
          });

          markerRefs.current[marker.id] = newMarker;
        });
      });
    };

    initializeMap();
  }, [bounds]);

  const removeSyndicMarkers = () => {
    Object.keys(markerRefs.current).forEach((id) => {
      const markerElement = markerRefs.current[id];
      if (markerElement && markerElement.dataset.type === "syndic") {
        markerElement.remove();
        delete markerRefs.current[id];
      }
    });
  };

  return {
    mapContainerRef,
    mapRef,
    markerRefs,
    popupRef,
  };
};

export default useMapInitialization;
